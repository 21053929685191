import Vue from "vue";
import display from "@/configure/display/core/user";
import {UserEntity, UserQuery, UserTeamEntity} from "@/core/entity/core/user";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";
import {CompanyEntity} from "@/core/entity/core/company";
import {OperatorEntity} from "@/core/entity/core/operator";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.teamKey, value: 'teamKey', width: 100},
            {text: display.name, value: 'name'},
            {text: display.account, value: 'account'},
            {text: display.mail, value: 'mail'},
            {text: display.level, value: 'level', width: 200},
            {text: display.phone, value: 'phone'},
            {text: display.operTime, value: 'operTime'},
            {text: display.operOperatorKey, value: 'operOperatorKey'},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <UserEntity>{},
        result: <UserQuery[]>[],
        resultLength: 0,
        datetime: "",
        display,
        teamList: [] as Array<UserTeamEntity>,
        operatorList:[] as Array<OperatorEntity>,
        level: [
            {
                key: 0,
                name: "最高权限"
            },
            {
                key: 1,
                name: "组级权限"
            },
            {
                key: 2,
                name: "业务级权限"
            },
        ]
    }),
    async mounted() {
        this.teamList = await service.userTeam.query({});
        this.operatorList=await service.operator.query({});
    },
    methods: {
        async query() {
            this.loading = true;
            try {
                const queryEntiy = this.$queryData(this.data, this.options, ["name"]);
                queryEntiy.userType = 1;
                const result = await service.user.query(queryEntiy);
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/sale/add"});
        },
        async load(key: number) {
            await this.$router.push({path: "/sale/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/sale/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.user.del(key);
                await this.query();
            }
        },
    }
});
